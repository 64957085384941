<template>
    <div class="cart-card" @touchstart="startSwipe" @touchmove="onSwipe" @touchend="endSwipe" ref="scrollContainer">
        <div class="card">
            <div class="left-content" v-if="list.status === '1'" @click="handleClick">
                <div class="img">
                    <div class="invalid">失效</div>
                    <img :src="list.img" alt="" width="75" height="75">
                </div>
                <div class="detail">
                    <div class="title" style="color: #969699;font-weight: 100;">{{ list.title }}</div>
                    <div style="height: 32px;"></div>
                    <div class="price" style="font-size: 14px;color:#222">
                        宝贝已下架，请浏览类似商品
                    </div>
                </div>
            </div>
            <div class="left-content" v-else>
                <img :src="list.img" alt="" width="75"  @click="goShopDescPage">
                <div class="detail">
                    <div class="title" @click="goShopDescPage">{{ list.title }}</div>
                    <div class="type"  @click="goShopDescPage">{{ list.type }}</div>
                    <div class="price">
                        <div class="number"><span>￥</span>{{ list.price }}</div>
                        <van-stepper v-model="list.amount" @change="changeAmount(list)" input-width="40px" button-size="28px"/>
                    </div>
                </div>
            </div>
            <div class="delete-button show-delete" @click.stop="deleteItem">
                删除
            </div>
        </div>
    </div>
</template>

<script>
import { Toast } from 'vant'
export default {
    name:'CartCard',
    methods:{
        changeAmount(list){
            this.$emit('changeAmount', list)
        },
        startSwipe(event) {
            this.startX = event.touches[0].clientX;
            this.list.isSwiping = false;
            this.$emit('resetSwiping', this.list.id);
            
        },
        goShopDescPage(){
            this.$emit('goShopDescPage', this.list)
        },
        handleClick(){
            Toast('商品已下架')
        },
        onSwipe(event) {
            if (!this.list.isSwiping && !this.scrolling) {
                const currentX = event.touches[0].clientX;
                const deltaX = currentX - this.startX;
                const scrollContainer = this.$refs.scrollContainer;
                // 左滑
                if (deltaX < -1 && !this.leftScroll) {
                    if (!this.swipeTimer) {
                        this.list.isSwiping = true;
                        const scrollRightPosition = scrollContainer.scrollWidth - scrollContainer.clientWidth;
                        // 滚动条滚动到最右侧
                        scrollContainer.scrollTo({left: scrollRightPosition, behavior: 'smooth'})
                        this.rightScroll = false
                        this.leftScroll = true
                        // 设置计时器，防止过快滑动
                        this.swipeTimer = setTimeout(() => {
                            this.swipeTimer = null;
                        }, 1000); // 1秒钟内只能执行一次滑动
                    }
                } else if (deltaX > 1 && !this.rightScroll) {
                    if (!this.swipeTimer) {
                        // 右滑 // 滚动条滚动到最左侧
                        scrollContainer.scrollTo({left: 0, behavior: 'smooth'})
                        this.rightScroll = true
                        this.leftScroll = false
                        // 设置计时器，防止过快滑动
                        this.swipeTimer = setTimeout(() => {
                            this.swipeTimer = null;
                        }, 1000); // 1秒钟内只能执行一次滑动
                    }
                }
            }
        },
        endSwipe() {
            this.list.isSwiping = false;
        },
        deleteItem() {
            this.$emit('deleteItem', this.list.id);
        },
    },
    data(){
        return {
            startX: 0, // 滑动偏移
            leftScroll: false,
            rightScroll: false,
            swipeTimer: null, // 用于限制滑动频率的计时器
        }
    },
    watch: {
        scrolling (val) {
            //监听滚动条状态，
            if (val) {
                const scrollContainer = this.$refs.scrollContainer;
                scrollContainer.scrollTo({left: 0, behavior: 'smooth'})
            }
        },
        'list.showDelBtn'(val){
            if(!val){
                const scrollContainer = this.$refs.scrollContainer;
                scrollContainer.scrollTo({left: 0, behavior: 'smooth'})
            }
        }
    },
    props: {
      list:{
        type: Object,
        default: ()=>{}
      },
      scrolling:{
        type: Boolean,
        default: false
      },
    }
  };
</script>
  
<style lang="scss" scoped>
  .cart-card {
    display: flex;
    justify-content: space-between;
    margin: 10px 15px;
    border-radius: 10px;
    overflow: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+, edge */
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .card {
        display: flex;
        background: #fff;
        border-radius: 10px;
        // padding: 5px 0;
        .left-content {
            display: flex;
            align-items: center;
            padding: 10px 0 10px 15px;
            .img {
                position: relative;
                .invalid {
                    position: absolute;
                    left: 5px;
                    top: 5px;
                    border-radius: 50%;
                    background: rgba(0,0,0,0.5);
                    width: 65px;
                    height: 65px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #ddd;
                }
            }
            img {
        
            }
            .detail {
                width: calc(100vw - 135px);
                text-align: left;
                margin: 0 0 0 15px;
                padding: 5px 0;
                .title {
                    color: #222;
                    font-weight: 600;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;    
                    -webkit-line-clamp: 1;    
                    overflow: hidden;
                    padding-right: 15px;
                }
                .type {
                    margin: 6px 0;
                    font-size: 14px;
                }
                .price {
                    display: flex;
                    justify-content: space-between;
                    margin-right: 15px;
                    .number {
                        font-weight: 600;
                    }
                }
            }
        }
    }
    .delete-button {
      width: 100px; /* 删除按钮的宽度 */
      color: #fff;
      background-color: red; /* 删除按钮的背景色 */
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0 10px 10px 0; //左上 右上 右下 左下 
      cursor: pointer;
      transition: transform 0.3s;
    }
  
    .show-delete .delete-button {
      transform: translateX(0); /* 显示删除按钮，按钮滑动到可见位置 */
    }
  }
</style>
  