<template>
    <div class="buy-footer">
        <div class="img">
            <img class="service" src="../../assets/images/service.png" alt="" width="30" height="30" v-show="showAddCartButton" @click="goCustomerServicePage">
            <badge :amount="cartAmount" :type="'cart'">
                <template v-slot:amount>
                    <img src="../../assets/images/cart-o.png" alt="" width="30" height="30" v-show="showAddCartButton" @click="goCartPage">
                </template>
            </badge>
        </div>
        <div class="button-buy-cart" @click="createOrder('addCart')" v-show="showAddCartButton">加入购物车</div>
        <div class="button-wait" @click="createOrder" v-if="isShortage">缺货</div>
        <div :class="buttonStyle" @click="createOrder('buy')" v-else>
            <span>{{ text }}</span>
            <span v-if="totalPrice">￥{{ totalPrice }}</span>
        </div>
        <!-- 全面屏适配 -->
        <div class="safe-area" style="background-color: #fff;" v-if="!disableSafeArea"></div>
    </div>
</template>

<script>
import badge from '../badge/index.vue'
export default {
  name: 'Buy',
  activated(){

  },
  components: {
    badge
  },
  data(){
      return {

      }
  },
  props:{
    isShortage: {
        type: Boolean,
        default: false
    },
    text:{
        type: String,
        default: '购买'
    },
    buttonStyle:{
        type: String,
        default: 'button-buy'
    },
    totalPrice:{
        type:[String, Number],
        default: ''
    },
    showAddCartButton:{
        type: Boolean,
        default: false
    },
    disableSafeArea:{
        type: Boolean,
        default: false
    },
    cartAmount: {
      default: 0,
      type: [Number, String]
    }
  },
  methods:{
    createOrder(type){
        this.$emit('createOrder', type)
    },
    goCartPage(){
        this.$router.push('/cart')
    },
    goCustomerServicePage(){
        this.$router.push('/customer-service')
    }
  },
}
</script>

<style scoped lang="scss">
.buy-footer {
    height: 55px;
    position: fixed;
    max-width: calc(625px - 20px);
    z-index: 1;
    bottom: constant(safe-area-inset-bottom);
    bottom: env(safe-area-inset-bottom);
    display: flex;
    padding: 0 10px;
    width: calc(100% - 20px);
    background: #fff;
    align-items: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-top: 1px rgba(0,0,0,0.05) solid;
    justify-content: flex-end;
    .img {
        position: absolute;
        display: flex;
        left: 5px;
        .service {
            margin: 0 10px;
        }
    }
}
.button-buy {
    width: 100px;
    height: 38px;
    display: flex;
    line-height: 30px;
    border-radius: 30px;
    align-items: center;
    font-size: 14px;
    color: #fff;
    background: #fc5f10;
    justify-content: center;
    border: 1px solid #fc5f10;
    cursor: pointer;
}
.button-wait {
    width: 100px;
    height: 38px;
    display: flex;
    line-height: 30px;
    border-radius: 30px;
    align-items: center;
    font-size: 14px;
    color: #fff;
    background: rgba(252,95,16,0.6);
    justify-content: center;
    border: 1px solid rgba(252,95,16,0.1);
}
.button-buy-cart {
    width: 100px;
    height: 38px;
    display: flex;
    margin: 0 10px;
    line-height: 30px;
    border-radius: 30px;
    align-items: center;
    font-size: 14px;
    color: #fff;
    background: #fbbc05;
    justify-content: center;
    border: 1px solid #fbbc05;
}
</style>
