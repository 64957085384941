<template>
    <div class="cart-box" id="cart">
        <div class="header">
          <div class="title">购物车</div>
          <div class="right">
            <div class="coupon" @click="goCouponPage">优惠券</div>
            <div class="trash" @click="resetCartConfirm">
              <img src="../../assets/images/delete.png" alt="" width="16" height="16">
              <span>清空</span>
            </div>
          </div>
        </div>
        <div class="cart" v-if="lists.length > 0">
          <cart-card :list="list" v-for="list in lists" :key="list.id" @changeAmount="changeAmount" @deleteItem="deleteItem"
            @resetSwiping="resetSwiping" @goShopDescPage="goShopDescPage" :scrolling="scrolling"/>
        </div>
        <div class="none-list" v-else>
            <img src="../../assets/images/none.svg" alt="">
            <div class="none">您还没有添加商品哦~</div>
            <div class="button-block">
              <div @click="goShopPage" class="button">去逛逛</div>
            </div>
        </div>
        <div class="recommend">
        </div>
        <buy v-show="lists.length > 0" :disableSafeArea="true" :text="'去结算'" :buttonStyle="'mfz-button-buy'" class="btn-footer" :totalPrice="totalPrice" @createOrder="submitOrder"/>
        <Confirm ref="confirm" @handleDelete="resetCart" />
    </div>
</template>

<script>
import CartCard from '../../components/card/CartCard.vue'
import Buy from '../../components/footer/Buy.vue'
import Confirm from '../../components/dialog/Confirm.vue';
const seo = require('../../../config/index')
import { queryAllCartByUid, deleteCartByCartId, deleteAllCartGoodsByUid, queryCartGoodsTotalAmount, updateCartByCartId } from '@/api/api'
// const seo = require('../../../config/index')
import { getopenIdByCode } from '@/utils/index';
import { Toast } from 'vant';

export default {
  name: 'Cart',
  components: {
    CartCard,
    Buy,
    Confirm,
  },
  created(){
    getopenIdByCode()
  },
  activated(){
    document.title = seo.config.seoPage.cartPage.title
    window.addEventListener('scroll', this.handleScroll)
    this.isLogin = localStorage.getItem('token') ? true : false
    this.getData()
  },
  deactivated(){
    document.removeEventListener('scroll', this.handleScroll);
  },
  data:()=>{
    return {
      lists: null,
      totalPrice: null,
      scrolling: false,
      cartAmount: 0,
      isLogin: localStorage.getItem('token') ? true : false,
      userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo'))[0] : {},
    }
  },
  methods:{
    getData(){
      if(this.isLogin){
        let uid = this.isLogin ? String(JSON.parse(localStorage.getItem('userInfo'))[0].uid) : ''
        queryAllCartByUid({ uid: Number(uid) }).then((res)=>{
          this.lists = []
          let tempArr = res.data.data.data
          tempArr.forEach((item)=>{
            let tempObj = {}
            tempObj.id = item.cart_id
            tempObj.img = item.imgUrl
            tempObj.title = item.title
            tempObj.type = item.type
            tempObj.price = item.price
            tempObj.amount = item.amount
            tempObj.isSwiping = false // 默认不展示左滑删除
            tempObj.showDelBtn = false // 默认不展示删除按钮
            tempObj.gid = item.gid
            tempObj.uid = item.uid
            tempObj.shop = item.shop
            tempObj.status = item.status
            this.lists.push(tempObj)
          })
          this.totalPrice = this.lists.reduce((total, item) => {
            if (item.status === '2') {
              return total + item.price * item.amount;
            } else {
              return total;
            }
          }, 0).toFixed(2);
        })
        this.initCartGoodsAmount() // 更新徽标
      } else {
        this.lists = []
      }
    },
    goShopDescPage(list){
      let goodDescInfo = {}
      goodDescInfo.type = list.type
      goodDescInfo.amount = list.amount
      sessionStorage.setItem('goodDescInfo', JSON.stringify(goodDescInfo))
      this.$router.push({ path:'/shop-desc', query:{ gid: list.gid } })
    },
    submitOrder(){
      if(this.isLogin){
        let tempArr = []
        this.lists.forEach(item=>{
          let temp = {}
          temp.gid = item.gid
          temp.type = item.type
          temp.title = item.title
          temp.imgUrl = item.img
          temp.amount = item.amount
          temp.shop = item.shop
          temp.originalCost = item.price
          temp.totalPrice = this.totalPrice
          if(item.status === '2'){
            tempArr.push(temp)
          }
        })
        if(tempArr.length > 0){
          sessionStorage.setItem('lists', JSON.stringify(tempArr))
          sessionStorage.setItem('fromPath', 'cart')
          this.$router.push({ path:'/pay', query:{ oid: new Date().getTime() } })
          // this.$router.push({ path:'/pay', query:{ oid: new Date().getTime(), gid: this.$route.query.gid } })
        } else {
          Toast('再去逛逛~')
        }
      } else {
        this.$router.push({ name:'Login', params:{ text:'选商品' } })
      }
    },
    handleScroll() {
      if (!this.scrolling) {
        this.scrolling = true; // Scroll has started
      }
      clearTimeout(this.scrollEndTimeout);
      this.scrollEndTimeout = setTimeout(() => {
        this.scrolling = false; // Scroll has ended
      }, 100);
    },
    resetSwiping(id){
      this.lists.forEach(item => {
        if (item.id === id) {
          item.showDelBtn = true;
        } else {
          item.showDelBtn = false;
        }
      });
    },
    initCartGoodsAmount(){
      if(this.isLogin){
          let uid = localStorage.getItem('userInfo') ? String(JSON.parse(localStorage.getItem('userInfo'))[0].uid) : ''
          queryCartGoodsTotalAmount({ uid: uid }).then((res)=>{
            this.$store.commit('Set_CartAmount_State', res.data.total_amount)
          })
      } else {
        this.$store.commit('Set_CartAmount_State', 0)
      }
    },
    resetCart(){
      let uid = this.isLogin ? String(JSON.parse(localStorage.getItem('userInfo'))[0].uid) : ''
      deleteAllCartGoodsByUid({ uid: uid }).then(()=>{
        this.$refs.confirm.show('clearCart')
        this.getData()
      })
    },
    deleteItem(id){
      deleteCartByCartId({ cart_id: id }).then(()=>{
        this.getData()
      })
    },
    changeAmount(list){
      updateCartByCartId({ cart_id:  list.id, amount: list.amount}).then(()=>{
        this.getData()
      })
    },
    goShopPage(){
      this.$router.push('/shop')
    },
    goCouponPage(){
      if(this.isLogin){
        if(this.lists.length > 0){
          sessionStorage.setItem('fromPath', 'cart')
        } else {
          sessionStorage.setItem('fromPath', 'nocartgoods')
        }
        this.$router.push('/coupon')
      } else {
        sessionStorage.setItem('fromPath', '/cart')
        this.$router.push({ name:'Login', params:{ text:'继续用' } })
      }
    },
    resetCartConfirm(){
      if(this.lists.length > 0){
        this.$refs.confirm.show('clearCart')
      }
    }
  },
}
</script>

<style scoped lang="scss">
.cart-box {
    background: #f3f3f3;
    .header {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      display: flex;
      background: #f3f3f3;
      justify-content: space-between;
      padding: 10px 15px;
      z-index: 1;
      .title {
        font-size: 16px;
        font-weight: 600;
        color: #222;
      }
      .right {
        display: flex;
        .coupon {
          margin: 0 10px;
        }
        .trash {
          color: rgba(0,0,0,0.3);
          display: flex;
          align-items: center;
          font-size: 16px;
          img {
            opacity: 0.5;
          }
        }
      }
    }
    .cart {
      margin: 50px 0 120px;
    }
    .none-list {
        margin: 80px auto;
        width: 100%;
        max-width: 625px;
        position: fixed;
        img {
          min-height: 142px;
        }
        .none {
            margin: 10px 0 0;
            color: #969699;
        }
    }
    .btn-footer {
      position: fixed;
      left: 0;
      right: 0;
      bottom: calc(55px + constant(safe-area-inset-bottom));
      bottom: calc(55px + env(safe-area-inset-bottom));
    }
    .button-block {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 15px;
      .button {
        height: 38px;
        width: 30%;
        display: flex;
        line-height: 30px;
        border-radius: 30px;
        align-items: center;
        font-size: 14px;
        color: #ec745b;
        justify-content: center;
        border: 1px solid #ec745b;
        cursor: pointer;
      }
    }
}
</style>